import { Link } from 'react-router-dom';
import { FaGithub, FaOrcid } from 'react-icons/fa6'
import { Document, GitHubProject } from './Card';

export default function Home() {
    return (
        <section id='home' className='container p-4 box-border'>
            <section id='about-me' className='container p-2 flex lg:flex-row flex-col min-h-[28rem] lg:h-max lg:justify-evenly lg:flex-nowrap box-border items-center content-center'>
                <section id='headshot' className='w-1/3 min-w-[28rem] p-0 box-border flex flex-col items-center justify-center'>
                    {/* <img src={headshot} alt='Me' className='w-full py-8 mx-auto' /> */}
                    <section id='hero-image' className='lg:w-1/3 w-full min-w-[28rem] p-0 box-border'>
                        <img src={process.env.PUBLIC_URL + 'imgs/headerphoto.png'} className='rounded-[50%] w-[28rem] h-[28rem] object-cover object-top'/>
                    </section>
                    <div id='social' className='p-2 w-full flex flex-row justify-evenly items-center child:p-1 child:m-0 child:rounded-full child:border-solid child:border-2 child:border-transparent child-hover:border-slate-500 child:ease-in-out child:transition-all child:duration-75'>
                        <Link to='https://github.com/ramanakumars' target="_blank" rel="noreferrer">
                            <FaGithub size='4rem' />
                        </Link>
                        <Link to='https://orcid.org/0000-0002-6794-7587' target="_blank" rel="noreferrer">
                            <FaOrcid size='4rem' />
                        </Link>
                    </div>
                </section>
                <section id='biosketch' className='flex flex-col flex-nowrap box-border lg:w-2/3 w-full items-center content-center p-2'>
                    <h1 className='text-4xl p-4 text-center font-semibold'>Welcome to my site!</h1>

                    <p className='p-2 text-lg'>
                        I am a postdoctoral researcher at the University of California, Berkeley, where I study convective cloud formation on Jupiter.
                        I work on using fluid dynamic models combined with cloud chemistry to simulate cloud formation on Gas Giant planets.
                        I am also interested in machine/deep learning techniques and data-driven strategies to study
                        and better understand the diversity of cloud feature on Gas Giant planets.
                    </p>

                    <p className='p-2 text-lg'>
                        I did my Ph.D. in Space Sciences at Florida Tech, where my focus was on numerical simulations of Jupiter's atmosphere, specifically in modelling convective thunderstorms. The focus of my dissertation work was using the EPIC (Explicit Planetary Isentropic Coordinate) model to study convective water clouds on Jupiter which have been observed to grow into large storm complexes that result in planetary scale disturbances. I helped add a sub-grid scale moist convective scheme to the model so as to simulate these storms. This update would prove useful not only in Jovian atmospheric studies but also in other Gas Giants where convective activity has been observed and theorized, and potentially even for exoplanets.
                    </p>

                    <p className='p-2 text-lg'>
                        I have also worked on detecting and modeling meteor impacts in planetary atmospheres. I worked with the Spalding Allsky Camera Network (SACN) to triangulate and determine trajectories of fireballs detected using their camera network, and implemented a fragmentation model to determine their physical properties. I applied a similar analysis to a jovian impact in 2019 which had sufficiently high temporal resolution to detect fragmentation peaks in the lightcurve.
                    </p>
                </section>
            </section>
            <section id='projects-container' className='container p-2 flex flex-col justify-center'>
                <h1 className='text-4xl font-bold p-4 text-center'>Code projects</h1>
                <section id='projects' className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mx-auto'>
                    <GitHubProject name='PatchGAN' github='https://github.com/ramanakumars/patchgan'>
                        Image-to-image translation model built in PyTorch.
                        Supports PyPI installation.
                    </GitHubProject>
                    <GitHubProject name='MetadataExplorer' github='https://github.com/ramanakumars/metadata-explorer'>
                        React/Plotly app to plot and explore image data with multiple metadata
                    </GitHubProject>
                    <GitHubProject name='TCuPGAN' github='https://github.com/ramanakumars/tcupgan'>
                        Image-cube or video segmentation model built in PyTorch.
                        Features 2DConvLSTM layers within a U-Net architecture.
                    </GitHubProject>
                    <GitHubProject name='bibtexparser' github='https://github.com/ramanakumars/bibtexparser'>
                        Flask App to parse bibtex entries into custom format.
                    </GitHubProject>
                    <GitHubProject name='JunoCamProjection' github='https://github.com/ramanakumars/JunoCamProjection'>
                        Code to project and mosaic JunoCam framelets, written in Python/C using the SPICE API.
                    </GitHubProject>
                </section>
            </section>

            <section id='docs-container' className='container p-2 flex flex-col box-border'>
                <h1 className='text-4xl font-bold p-4 text-center'>Posters and talks</h1>
                <section id='docs' className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mx-auto'>
                    <Document name='DPS 2023 poster' link='/DPS2023.pdf'>
                        Poster for the DPS-EPSC 2023 conference, titled "How are Jupiter’s belts and zones affected by deep convection: a parameterized numerical study".
                    </Document>
                    <Document name='EPSC 2020 talk' link='https://vimeo.com/457697239'>
                        Talk on Vimeo for the 2020 EPSC conference, titled "Moist convection in Jupiter’s fastest jet".
                    </Document>
                </section>
            </section>

        </section>
    )
}
